import { ChainId } from '@usedapp/core'
import { Mainnet, Rinkeby } from '@usedapp/core'

const SupportedChain = Mainnet

const config = {
  networks: [SupportedChain],
  readOnlyChainId: SupportedChain.chainId,
  readOnlyUrls: {
    [ChainId.Mainnet]: 'https://eth-mainnet.alchemyapi.io/v2/j-laCoDEuVRQkXht0rsTRRhogpfwXG5t',
    [ChainId.Rinkeby]: 'https://rinkeby.infura.io/v3/4b72c849071a41dbaf5eee4355478564',
  },
  multicallAddresses: {
    [ChainId.Mainnet]: Mainnet.multicallAddress,
    [ChainId.Rinkeby]: Rinkeby.multicallAddress,
  }
}

const goldenDAZKContractAddress = '0x3a1Cd3a0A1118a97cAeFB8c2d2d95E828bE7AfC6'

export { config, goldenDAZKContractAddress }
