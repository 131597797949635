import { useState, useEffect } from 'react'
import { BigNumber } from 'ethers'
import { useConfig, useEthers, useBlockNumber, useEtherBalance } from '@usedapp/core'
import { useGoldenDAZKBalance,
    useEthPerGoldenDAZK,
    useMaxTokens,
    useTotalSupply,
    useMintWithETH, } from '../../hooks'
import { Transactions } from '../notifications/Transactions'
import Stats from './Stats'

global.Buffer = global.Buffer || require('buffer').Buffer

function Main() {

    const { activateBrowserWallet, account } = useEthers()
    const { networks } = useConfig()
    const blockNumber = useBlockNumber()
    const ethPerGoldenDAZK = useEthPerGoldenDAZK()
    const ethBalance = useEtherBalance(account)
    const goldenDAZKBalance = useGoldenDAZKBalance(account)
    const maxTokens = useMaxTokens()
    const totalSupply = useTotalSupply()
    const goldenDAZKAvailable = totalSupply && maxTokens && totalSupply.lt(maxTokens)
    // const contractOwner = useContractOwner()
    // const isContractOwner = account && contractOwner && contractOwner == account

    const { mintWithETH, mintWithETHState } = useMintWithETH()
    const [mintWithETHErrorMessage, setMintWithETHErrorMessage] = useState()

    // const {setGoldenAndDistributeETH, setGoldenAndDistributeETHState} = useSetGoldenAndDistributeETH()
    // const [goldenAndDistributeETHErrorMessage, setGoldenAndDistributeETHErrorMessage] = useState()

    const [errorMessage, setErrorMessage] = useState()
    const [valWithETH, setValWithETH] = useState(1)

    useEffect(() => {
        console.log("ethPerGoldenDAZK", ethPerGoldenDAZK && ethPerGoldenDAZK.toString())
        console.log("valWithETH", valWithETH)
        console.log("eth cost", ethPerGoldenDAZK && ethPerGoldenDAZK.mul(valWithETH).div(BigNumber.from("10").pow(14)).toNumber().toFixed(4)/10000)
    }, [ethPerGoldenDAZK, valWithETH])


    const mintWithETHBtnClick = async () => {
        setMintWithETHErrorMessage()
        if (valWithETH && valWithETH > 0 && valWithETH <= 20) {
            try {
                await mintWithETH(valWithETH, { value: ethPerGoldenDAZK.mul(valWithETH) })
                    .catch((e) => {
                        console.log(e)
                        if (e) {
                            setMintWithETHErrorMessage(e.message)
                        }
                    })
            } catch (e) {
                setMintWithETHErrorMessage(e.message)
            }
        } else {
            setMintWithETHErrorMessage("Enter number between 1 and 20")
        }
    }

    useEffect(() => {
        if (mintWithETHState && mintWithETHState.status === 'Exception') {
            setMintWithETHErrorMessage(mintWithETHState.errorMessage)
            console.log(mintWithETHState)
        }
    }, [mintWithETHState])



    useEffect(() => {
        window.ethereum && window.ethereum.on('chainChanged', (chainId) => {
            if (chainId !== '0x' + networks[0].chainId) {
                window.ethereum.request({
                    method: 'wallet_switchEthereumChain',
                    params: [{ chainId: "0x" + networks[0].chainId }],
                })
            }
        })
    }, [networks])

    const onConnectWallet = async () => {
        setErrorMessage()
        activateBrowserWallet(async (er) => {
            if (er) {
                console.log(er)
                await window.ethereum.request({
                    method: 'wallet_switchEthereumChain',
                    params: [{ chainId: "0x" + networks[0].chainId }],
                })
                activateBrowserWallet()
            }
        }, false)
    }

    return (
        <main className="bg-white sm:max-w-[1024px] mx-auto flex rounded-xl py-6 px-4 my-8 drop-shadow flex-col items-center h-full">
            {!blockNumber && <div className="absolute z-10 w-full h-full backdrop-blur-sm"></div>}
            <div className="w-full">
                {!account &&
                    <button type="button" className="w-full px-5 py-3 font-semibold text-center text-white bg-yellow-500 rounded hover:bg-yellow-600 active:bg-yellow-500 disabled:bg-yellow-300 disabled:cursor-default" onClick={onConnectWallet}>Connect Wallet</button>}
                {account &&
                    <div>
                        <Stats blockNumber={blockNumber}
                            ethBalance={ethBalance}
                            goldenDAZKBalance={goldenDAZKBalance}
                            maxTokens={maxTokens}
                            totalSupply={totalSupply}
                        />

                        <div>
                            <div className="rounded-xl p-10 m-auto overflow-hidden">
                                <img className="m-auto" src="/placeHolder.gif" alt="Punch" />
                            </div>
                        </div>
                        {goldenDAZKAvailable && <>
                            <h1 className="my-2 text-2xl font-bold text-center">Get the Golden DAZK</h1>
                            <h5 className="mt-4 text-xl text-center text-gray-400">Mint with ETH</h5>
                            <form className="w-full">
                                <div>
                                    <div>
                                        <label htmlFor="quant" className="text-lg block font-medium leading-10 text-center text-gray-500 overflow-ellipsis">Enter quantity:</label>
                                        <input type="number" min="1" max={ethBalance && ethPerGoldenDAZK && ethBalance.div(ethPerGoldenDAZK).toString()} onFocus={(e) => e.target.select()} value={valWithETH} onChange={(e) => { setValWithETH(e.target.value) }} name="quantity" autoFocus className="w-full py-2 mb-4 text-lg font-bold text-center text-blue-600 border outline-none" />
                                    </div>
                                </div>
                                {account && ethBalance && ethPerGoldenDAZK && ethBalance.gte(ethPerGoldenDAZK.mul(valWithETH || 1)) && <button type="button" className="w-full px-5 py-3 font-semibold text-center text-white bg-emerald-500 rounded hover:bg-emerald-600 active:bg-emerald-500 disabled:bg-emerald-300 disabled:cursor-default" onClick={async () => await mintWithETHBtnClick().catch((e) => console.log("mint error", e))}>Mint with {ethPerGoldenDAZK && ethPerGoldenDAZK.mul(valWithETH).div(BigNumber.from("10").pow(14)).toNumber().toFixed(4)/10000} ETH</button>}
                                {account && ethBalance && ethPerGoldenDAZK && ethBalance.lt(ethPerGoldenDAZK.mul(valWithETH || 1)) && <button type="button" className="w-full px-5 py-3 font-semibold text-center text-white bg-gray-500 rounded hover:bg-gray-600 active:bg-gray-500 disabled:bg-gray-300 disabled:cursor-default" disabled>Insuffucient ETH</button>}
                                {mintWithETHErrorMessage && <h5 className="text-pink-800">{mintWithETHErrorMessage}</h5>}
                            </form>
                        </>}
                        {maxTokens && !goldenDAZKAvailable && <button type="button" className="w-full px-5 py-3 mb-8 mt-4 font-semibold text-center text-white bg-gray-500 rounded hover:bg-gray-600 active:bg-gray-500 disabled:bg-gray-300 disabled:cursor-default" disabled>All Golden DAZKs has been minted!</button>}
                    </div>
                }
            </div>
            <span className="mt-3 text-gray-500" style={{ fontFamily: "RobotoMedium" }}>Current block: {blockNumber}</span>
            <div className='w-full mt-3 text-gray-500' style={{ fontFamily: "RobotoMedium" }}>
                {errorMessage && <h5 className="text-pink-800">{errorMessage}</h5>}
                <div className='w-full text-blue-400'>{<Transactions />}</div>
            </div>
        </main>
    )
}


export default Main;