import logo1 from '../../logoLea.jpg'

function Header(){
    return(
        <header className=" flex flex-col md:flex-row">
            <img alt="Logotype" src={logo1} width={120} height={120}/>
        </header>
    )
}


export default Header;

