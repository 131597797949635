import { utils } from 'ethers'
import clsx from 'clsx'

const StatsCard = ({ title, data, icon, iconcolor, iconbg }) => {
    return (
        <div className="min-w-0 rounded-lg shadow-xs border-2 overflow-hidden bg-amber-50 shadow-gray-800" >
            <div className="p-4 flex items-center">
                <div className={clsx("p-3 rounded-full mr-4", iconcolor || "text-orange-500", iconbg || "bg-orange-100")}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20" className="w-5 h-5">
                        {icon || <path d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z"></path>}
                    </svg>
                </div>
                <div>
                    <div className="mb-2 text-sm font-medium text-gray-600">
                        {title}
                    </div>
                    <p className="text-lg font-semibold text-gray-700 capitalize">
                        {data}
                    </p>
                </div>
            </div>
        </div>
    )
}


const Stats = ({ blockNumber, ethBalance, goldenDAZKBalance, maxTokens, totalSupply }) => {
    const chainIcon = <path fillRule="evenodd" d="M12.586 4.586a2 2 0 112.828 2.828l-3 3a2 2 0 01-2.828 0 1 1 0 00-1.414 1.414 4 4 0 005.656 0l3-3a4 4 0 00-5.656-5.656l-1.5 1.5a1 1 0 101.414 1.414l1.5-1.5zm-5 5a2 2 0 012.828 0 1 1 0 101.414-1.414 4 4 0 00-5.656 0l-3 3a4 4 0 105.656 5.656l1.5-1.5a1 1 0 10-1.414-1.414l-1.5 1.5a2 2 0 11-2.828-2.828l3-3z" clipRule="evenodd" />
    const usdIcon = <>
        <path d="M8.433 7.418c.155-.103.346-.196.567-.267v1.698a2.305 2.305 0 01-.567-.267C8.07 8.34 8 8.114 8 8c0-.114.07-.34.433-.582zM11 12.849v-1.698c.22.071.412.164.567.267.364.243.433.468.433.582 0 .114-.07.34-.433.582a2.305 2.305 0 01-.567.267z" />
        <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-13a1 1 0 10-2 0v.092a4.535 4.535 0 00-1.676.662C6.602 6.234 6 7.009 6 8c0 .99.602 1.765 1.324 2.246.48.32 1.054.545 1.676.662v1.941c-.391-.127-.68-.317-.843-.504a1 1 0 10-1.51 1.31c.562.649 1.413 1.076 2.353 1.253V15a1 1 0 102 0v-.092a4.535 4.535 0 001.676-.662C13.398 13.766 14 12.991 14 12c0-.99-.602-1.765-1.324-2.246A4.535 4.535 0 0011 9.092V7.151c.391.127.68.317.843.504a1 1 0 101.511-1.31c-.563-.649-1.413-1.076-2.354-1.253V5z" clipRule="evenodd" />
    </>

    const serumIcon = <path fillRule="evenodd" d="M9 3a1 1 0 012 0v5.5a.5.5 0 001 0V4a1 1 0 112 0v4.5a.5.5 0 001 0V6a1 1 0 112 0v5a7 7 0 11-14 0V9a1 1 0 012 0v2.5a.5.5 0 001 0V4a1 1 0 012 0v4.5a.5.5 0 001 0V3z" clipRule="evenodd" />
    const serumsOwnedTitle = <a className="cursor-pointer group" rel="noreferrer" target="_blank" href="https://opensea.io/account/the-serum?search[resultModel]=ASSETS">
        Tokens Owned
        </a>
    return (
        <div className="grid gap-6 mb-8 md:grid-cols-2 xl:grid-cols-4">
            <StatsCard title="Current Block" data={blockNumber} icon={chainIcon} iconcolor="text-emerald-500" iconbg="bg-emerald-100" />
            <StatsCard title="ETH Balance" data={ethBalance && Number.parseFloat(utils.formatEther(ethBalance)).toFixed(4)} icon={usdIcon} iconcolor="text-slate-500" iconbg="bg-slate-100" />
            <StatsCard title={serumsOwnedTitle} data={goldenDAZKBalance && goldenDAZKBalance.toString()} icon={serumIcon} iconcolor="text-lime-500" iconbg="bg-lime-100" />
            <StatsCard title="Minted" data={totalSupply && totalSupply.toString() + " / " + maxTokens.toString()} icon={serumIcon} iconcolor="text-teal-500" iconbg="bg-teal-100" />

        </div>)
}
export default Stats