import { useContractCalls, useContractFunction } from '@usedapp/core'
import { Contract } from '@ethersproject/contracts'
import { utils } from 'ethers'
import { call, makeUseBalance } from './hooks'
import abijson from './abis/GoldenDAZK.json'
import { goldenDAZKContractAddress } from '../config'

const contractInterface = new utils.Interface(abijson.abi)
const contract = new Contract(goldenDAZKContractAddress, contractInterface)

const contractCall = call(contractInterface, goldenDAZKContractAddress)

const useGoldenDAZKBalance = makeUseBalance(contractCall)

const useEthPerGoldenDAZK = () => {
    const [price] = useContractCalls(
        [contractCall('price')]
    ) ?? []
    return price?.[0]
}

const useMaxTokens = () => {
    const [maxTokens] = useContractCalls(
        [contractCall('MAX_TOKENS')]
    ) ?? []
    return maxTokens?.[0]
}

const useTotalSupply = () => {
    const [totalSupply] = useContractCalls(
        [contractCall('totalSupply')]
    ) ?? []
    return totalSupply?.[0]
}

const useContractOwner = () => {
    const [owner] = useContractCalls(
        [contractCall('owner')]
    ) ?? []
    return owner?.[0]
}

const useMintWithETH = () => {
    const fn = useContractFunction(contract, 'mint')
    return { mintWithETH: fn.send, mintWithETHState: fn.state, mintWithETHEvents: fn.events }
}

const useSetGoldenAndDistributeETH = () => {
    const fn = useContractFunction(contract, 'setGoldenAndDistributeETH')
    return { setGoldenAndDistributeETH: fn.send, setGoldenAndDistributeETHState: fn.state, setGoldenAndDistributeETHEvents: fn.events }
}


export {
    useGoldenDAZKBalance,
    useEthPerGoldenDAZK,
    useMaxTokens,
    useTotalSupply,
    useContractOwner,
    useMintWithETH,
    useSetGoldenAndDistributeETH,
}